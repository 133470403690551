import React, { useEffect } from "react";
import useApi from "../hooks/http/useApi";
import Clover from "../components/checkin/Clover";
import { useParams } from "react-router";
import PageError from "../components/common/PageError";
import PageLoader from "../components/common/PageLoader";

const CloverReciept = () => {
    const [{ isLoading, isError, data }, setConfig] = useApi();
    const { merchantId, orderId } = useParams();

    useEffect(() => {
        setConfig({
            url: "customer/check-in/clover",
            data: {
                merchant_id: merchantId,
                order_id: orderId,
            },
            method: "POST",
        });
    }, []);

    if (isLoading) return <PageLoader />;
    if (isError) return <PageError  isError={isError} />;
    if (data) return <Clover data={data} />;
    return null;
};

export default CloverReciept;
