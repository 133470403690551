import { useEffect, useReducer, useState } from "react";
import apiClient from "../../api";

const dataFetchReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_INIT":
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                isError: action.payload,
            };
        default:
            throw new Error();
    }
};

const useApi = () => {
    const [config, setConfig] = useState();

    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: undefined,
    });

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            dispatch({ type: "FETCH_INIT" });

            try {
                const result = await apiClient(config);

                if (!didCancel) {
                    dispatch({ type: "FETCH_SUCCESS", payload: result.data });
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: "FETCH_FAILURE", payload: error?.response?.data?.message ?? "Something went wrong" });
                }
            }
        };

        if (config) {
            fetchData();
        }

        return () => {
            didCancel = true;
        };
    }, [config]);

    return [state, setConfig];
};

export default useApi;
