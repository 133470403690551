import React, { useEffect } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import LoyalteCard from "@softria.ca/loyalte-card";
import useApi from "../hooks/http/useApi";
import { useParams } from "react-router-dom";
import BgLayout from "../components/common/BgLayout";
import bgImage from "../assets/images/pass.jpeg";
import PageLoader from "../components/common/PageLoader";
import PageError from "../components/common/PageError";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        width: "100%",
        maxWidth: "400px",
        flex: 1,
    },
    errorImage: {
        width: "100%",
        height: "320px",
        margin: theme.spacing(1),
    },
    passCardContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    downloadMessage: {
        textAlign: "center",
        margin: "15px",
    },
    downloadTitle: {
        textAlign: "center",
        fontWeight: "bold",
    },
    downloadImage: {
        maxWidth: "100%",
        height: "56px",
    },
    business: {
        marginTop: theme.spacing(3),
        textAlign: "center",
        fontWeight: "bold",
    },
    powered: {
        marginTop: theme.spacing(2),
        textAlign: "center",
        fontWeight: "bold",
    },
}));

const toNum = (num = 0) => {
    const val = parseFloat(num);
    return isNaN(val) ? 0 : val;
};

const Pass = ({ business, cardDesign, customer, reward = {} }) => {
    const classes = useStyles();
    const nextLevel = toNum(customer?.points_until_next_reward);

    return (
        <BgLayout businessName={business?.name} bgImage={bgImage}>
            <div className={classes.paper}>
                <div className={classes.passCardContainer}>
                    <LoyalteCard
                        textColor={cardDesign?.text_color}
                        backgroundColor={cardDesign?.background_color}
                        logoImage={cardDesign?.icon_url}
                        title={business?.name}
                        points={customer?.points_balance}
                        stripImage={cardDesign?.strip_image_url}
                        name={`${customer?.first_name ?? ""} ${
                            customer?.last_name ?? ""
                        }`}
                        rewards={reward?.name ? reward.name : reward?.title}
                        level={customer?.current_level?.name}
                        nextLevel={nextLevel > 0 ? nextLevel : 0}
                        cardNumber={customer?.card_number}
                    />
                </div>
                <Typography
                    className={classes.downloadMessage}
                    variant="subtitle2"
                >
                    Add this card to your mobile wallet
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Typography
                            className={classes.downloadTitle}
                            variant="body1"
                        >
                            iPhone
                        </Typography>
                        <a
                            href={customer.pass?.apple_pass_download_url}
                            target="_blank"
                        >
                            <img
                                className={classes.downloadImage}
                                alt="Apple Wallet Pass"
                                src={cardDesign.apple_wallet_image_url}
                            />
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            className={classes.downloadTitle}
                            variant="body1"
                        >
                            Android phone
                        </Typography>
                        <a
                            href={customer.pass?.android_pass_download_url}
                            target="_blank"
                        >
                            <img
                                className={classes.downloadImage}
                                alt="Android Wallet Pass"
                                src={cardDesign.android_wallet_image_url}
                            />
                        </a>
                    </Grid>
                </Grid>
            </div>
        </BgLayout>
    );
};

const DownloadPass = () => {
    const [{ isLoading, isError, data }, setConfig] = useApi();
    const { customerId } = useParams();

    useEffect(() => {
        setConfig({
            url: `customer/${customerId}`,
        });
    }, []);

    if (isLoading) {
        return <PageLoader />;
    }
    if (isError) {
        return <PageError isError={isError} />;
    }
    if (data)
        return (
            <Pass
                business={data?.business}
                cardDesign={data?.card_design}
                customer={data?.customer}
                reward={data?.reward}
            />
        );

    return null;
};

const DownloadPassPage = () => {
    return (
        <>
            <Helmet>
                <title>Download Reward Card</title>
            </Helmet>
            <DownloadPass />
        </>
    );
};

export default DownloadPassPage;
