import React from 'react';
import {Box, Container, CssBaseline, makeStyles, Typography} from "@material-ui/core";
import {ReactComponent as Error} from "./images/error-404.svg";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '320px',
        margin: theme.spacing(1),
    },
    title: {
        textAlign: 'center',
        margin: theme.spacing(4, 0),
    },
    desc: {
        textAlign: 'center',
    },
}));

const Error404 = () => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Error className={classes.image}/>
                <Typography className={classes.title} variant="h4" color="primary">
                    <Box fontWeight="fontWeightBold">
                        Oops, page not found
                    </Box>
                </Typography>
                <Typography className={classes.desc} variant="body1">
                    We're very sorry for the inconvenience. It looks like you're trying to access a page that has been
                    deleted or never even existed.
                </Typography>
            </div>
        </Container>
    )
}

export default Error404