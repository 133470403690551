import React, { useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    makeStyles,
    Typography,
} from "@material-ui/core";
import TextMaskCustom from "../TextMaskCustom";
import TextInput from "./TextInput";
import { BgLayoutContent } from "../common/BgLayout";
import useSubmit from "../../hooks/http/useSubmit";
import { useParams } from "react-router-dom";
import TermsModal from "./TermsModal";
import { Helmet } from "react-helmet";

function telephoneCheck(str = "") {
    return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(
        str
    );
}

function emailCheck(str = "") {
    return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
        str
    );
}

const useStyles = makeStyles((theme) => ({
    earned: {
        textAlign: "center",
        fontWeight: 500,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
        textAlign: "center",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    title: {
        textAlign: "center",
        margin: theme.spacing(2, 0, 2),
        fontSize: "16px",
        fontWeight: 400,
    },
    enrollTerms: {
        margin: theme.spacing(2, 0, 2),
        textAlign: "center",
    },
    rewardTerms: {
        marginTop: theme.spacing(2),
        textAlign: "left",
    },
    business: {
        marginTop: theme.spacing(3),
        textAlign: "center",
        fontWeight: "bold",
    },
    powered: {
        marginTop: theme.spacing(2),
        textAlign: "center",
        fontWeight: "bold",
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        padding: theme.spacing(1.5, 0, 1.5, 0),
        textTransform: "none",
        fontWeight: 500,
        background: "#fff",
        color: "#000",
    },
    errorBox: {
        color: "red",
        fontSize: "14px",
        margin: theme.spacing(1, 0, 0),
        textAlign: "left",
    },
    consentText : {
        marginBottom: 0,
        textAlign: "left",
        marginTop: 5,
        fontSize: "12px"
    },
    pointsEarned: {
        display: "inline-block",
        backgroundColor: "#3B88C3",
        padding: "0px 10px",
        borderRadius: "5px",
    }
}));

const CheckinForm = ({ setNumber, setCustomer, businessName }) => {
    const [fetching, submit] = useSubmit();
    const classes = useStyles();
    const { merchantId, orderId } = useParams();
    const [phone, setPhone] = useState(null);
    const [errMsg, setErr] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!phone) {
            setErr("Mobile Number is required");
            return false;
        }
        if (!telephoneCheck(phone)) {
            setErr("Mobile Number is invalid");
            return false;
        }
        try {
            const res = await submit({
                url: "customer/check-in/clover",
                method: "POST",
                data: {
                    merchant_id: merchantId,
                    order_id: orderId,
                    phone_number: phone,
                },
            });
            if (res?.customer?._id) {
                setCustomer({
                    customer: res?.customer,
                    feedbackQuestion: res?.feedback_question,
                });
            } else {
                setNumber(phone);
            }
        } catch (err) {
            if (!err.isCancelled) {
                setErr(err.message);
            }
        }
    };

    return (
        <>
            <form className={classes.form} noValidate onSubmit={onSubmit}>
                <TextInput
                    name="phone_number"
                    label="Mobile Number"
                    type="text"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                    value={phone}
                    onChange={(e) => {
                        if (errMsg) {
                            setErr("");
                        }
                        setPhone(e.target.value);
                    }}
                />
                {errMsg && <div className={classes.errorBox}>{errMsg}</div>}
                <Typography className={classes.consentText}>
                    By enrolling to {businessName} rewards program, you consent to recieve SMS/Email/Push communications.
                </Typography>
                <Button
                    className={classes.submit}
                    type="submit"
                    size="large"
                    fullWidth
                    disabled={fetching}
                >
                    {fetching ? (
                        <CircularProgress style={{ color: "#000" }} size={24} />
                    ) : (
                        "Check-In & Earn Rewards"
                    )}
                </Button>
            </form>
        </>
    );
};

const EnrollForm = ({ phone, code, setCustomer, businessName }) => {
    const [fetching, submit] = useSubmit();
    const classes = useStyles();
    const { orderId } = useParams();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [errMsg, setErr] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!name) {
            setErr("Name is required");
            return;
        }
        if (email) {
            if (!emailCheck(email)) {
                setErr("Email is invalid");
                return;
            }
        }
        try {
            let data = {
                order_id: orderId,
                phone_number: phone,
                name,
            };
            if (email) {
                data.email = email;
            }
            const res = await submit({
                url: `customer/enroll/clover?business=${code}`,
                method: "POST",
                data,
            });
            if (res?.customer?._id) {
                setCustomer({
                    customer: res?.customer,
                    feedbackQuestion: res?.feedback_question,
                });
            }
        } catch (err) {
            if (!err.isCancelled) {
                setErr(err.message);
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>Enroll</title>
            </Helmet>
            <form className={classes.form} noValidate onSubmit={onSubmit}>
                <div style={{ marginBottom: "15px" }}>
                    <TextInput
                        name="name"
                        label="Full Name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={(e) => {
                            if (errMsg) {
                                setErr("");
                            }
                            setName(e.target.value);
                        }}
                    />
                </div>
                <TextInput
                    className={classes.input}
                    name="email"
                    label="Email (optional)"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => {
                        setErr("");
                        setEmail(e.target.value);
                    }}
                />
                {errMsg && <div className={classes.errorBox}>{errMsg}</div>}
                <Typography className={classes.consentText}>
                    By enrolling to {businessName} rewards program, you consent to recieve SMS/Email/Push communications.
                </Typography>
                <Button
                    className={classes.submit}
                    type="submit"
                    size="large"
                    fullWidth
                    disabled={fetching}
                >
                    {fetching ? (
                        <CircularProgress style={{ color: "#000" }} size={24} />
                    ) : (
                        "Enroll & Earn Rewards"
                    )}
                </Button>
            </form>
        </>
    );
};

function toNum(val = 0) {
    const num = parseFloat(val);
    return isNaN(num) ? 0 : num;
}

const CheckIn = ({ business, loyaltyProgram, pointsEarned, setCustomer }) => {
    const classes = useStyles();
    const [phone, setPhone] = useState(null);

    const getProgramType = () => {
        if (loyaltyProgram.type === "visit") {
            return "visit";
        } else if (loyaltyProgram.type === "spend") {
            return "purchase";
        } else return null;
    };

    return (
        <BgLayoutContent>
            <div className={classes.paper}>
                <Typography
                    variant="h5"
                    color="textPrimary"
                    className={classes.earned}
                >
                    <Box>
                        You have earned <span className={classes.pointsEarned}>{pointsEarned}</span> points
                        <br />
                        on this {getProgramType()}!
                    </Box>
                </Typography>
                {loyaltyProgram.sign_up_bonus && (
                    <Typography
                        className={classes.title}
                        variant="h6"
                        color="textPrimary"
                    >
                        <Box>
                            Get {loyaltyProgram?.sign_up_bonus?.reward_name ?? ""} as
                            welcome reward!*
                        </Box>
                    </Typography>
                )}
                {phone ? (
                    <EnrollForm
                        code={business?.code}
                        phone={phone}
                        setCustomer={setCustomer}
                        businessName={business?.name}
                    />
                ) : (
                    <CheckinForm
                        setNumber={setPhone}
                        setCustomer={setCustomer}
                        businessName={business?.name}
                    />
                )}
                <Typography
                    className={classes.enrollTerms}
                    variant="body2"
                    color="textPrimary"
                >
                    You agree to the{" "}
                    <TermsModal
                        title="Terms & Privacy Policy"
                        content={loyaltyProgram?.terms_and_conditions}
                    />{" "}
                    of {business?.name} by enrolling to the rewards
                    program.
                </Typography>
                {loyaltyProgram.sign_up_bonus && (
                    <Typography
                        className={classes.rewardTerms}
                        variant="caption"
                        color="textPrimary"
                    >
                        * Welcome reward can be used on your next{" "}
                        {getProgramType()} and valid only for{" "}
                        {loyaltyProgram.sign_up_bonus.expire_in} days. Welcome
                        reward is only valid to new customers.
                    </Typography>
                )}
            </div>
        </BgLayoutContent>
    );
};

export default CheckIn;
