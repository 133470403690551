import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Error404 from "./assets/Error404";
import DownloadPass from "./pages/DownloadPass";
import CloverReciept from "./pages/CloverReciept";
import PageError from "./components/common/PageError";
import { Helmet } from "react-helmet";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Helmet titleTemplate="%s | RewardUp">
                <title>Check-in</title>
            </Helmet>
            <Router>
                <Switch>
                    <Route path="/clover-receipt/:merchantId/:orderId">
                        <CloverReciept />
                    </Route>
                    <Route path="/customer/:customerId/pass/download">
                        <DownloadPass />
                    </Route>
                    <Route path="*">
                        <PageError isError="Page Not Found" />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
};

export default App;
