import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/images/logo.svg";

const useStyles = makeStyles({
    con: {
        minHeight: "100vh",
        position: "relative",
        background: "rgba(0,0,0,.7)",
        // justifyContent: "center",
        padding: "90px 15px 15px 15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    bgImage: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: -1,
    },
    form: {
        width: "100%",
        maxWidth: "500px",
        background: "rgba(0,0,0,.5)",
        padding: "45px 25px",
        color: "#fff",
        borderRadius: "10px",
        backdropFilter: "blur(5px)",
    },
    header: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        padding: "0px 1rem",
        backgroundColor: "rgba(0,0,0,.7)",
        height: "60px",
        backdropFilter: "blur(5px)",
        color: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "20px",
        lineHeight: 1,
        fontWeight: 600,
    },
    truncate: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        minWidth: 0,
        marginRight: "5px",
    },
    footer: {
        marginTop: "30px",
        textAlign: "center",
        fontSize: "12px",
        lineHeight: 1.8,
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});

const BgLayout = ({ children, bgImage, businessName }) => {
    const styles = useStyles();

    return (
        <div className={styles.con}>
            <img src={bgImage} className={styles.bgImage} alt="" />
            <header className={styles.header}>
                <div className={styles.truncate}>{businessName}</div>
                <div>
                    <img src={logo} height="40px" alt="" />
                </div>
            </header>
            <div className={styles.content}>{children}</div>
            <div className={styles.footer}>
                © 2021 RewardUp Inc. All rights reserved.
                <br />1 Yonge Street, Toronto, ON, M5E 1W7, Canada.
                <br />
                Powered by RewardUp
            </div>
        </div>
    );
};

export const BgLayoutContent = ({ children }) => {
    const styles = useStyles();

    return <div className={styles.form}>{children}</div>;
};

export default BgLayout;
