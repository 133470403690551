import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export default withStyles({
    root: {
        color: "#fff",
        "& label": {
            color: "#fff",
        },
        "& label.Mui-focused": {
            color: "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff"
        }
    },
})(TextField);
