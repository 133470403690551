import React from 'react'
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const TextMaskCustom = ({inputRef, ...other}) => {

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'_'}
        />
    );
}

export default TextMaskCustom

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
