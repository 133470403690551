import axios from 'axios'

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    },
})

export const checkIn = async (payload) => {
    return apiClient.post('customer/check-in/clover', payload)
        .then(response => ({response}))
        .catch(error => ({error}))
}

export const enroll = async (customer_id, payload) => {
    return apiClient.post(`customer/${customer_id}/enroll/clover`, payload)
        .then(response => ({response}))
        .catch(error => ({error}))
}

export const feedbackAnswer = async (question_id, customer_id, payload) => {
    return apiClient.post(`feedback/question/${question_id}/customer/${customer_id}/answer`, payload)
        .then(response => ({response}))
        .catch(error => ({error}))
}

export const getCustomer = async (customer_id) => {
    return apiClient.get(`customer/${customer_id}`)
        .then(response => ({response}))
        .catch(error => ({error}))
}

export default apiClient
