import { Typography } from "@material-ui/core";
import React from "react";
import errorImage from "../../assets/images/error-404.svg";

const PageError = ({ isError }) => {
    return (
        <div
            style={{
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                backgroundColor: "#121212",
            }}
        >
            <img src={errorImage} width="280px" alt="" />
            <Typography
                style={{
                    color: "red",
                    fontSize: "22px",
                    margin: "15px 0px 10px 0px",
                }}
            >
                {isError}
            </Typography>
            <div style={{ color: "#fff", fontSize: "14px" }}>
                Please contact{" "}
                <a href="mailto:support@rewardup.com" style={{ color: "#fff" }}>support@rewardup.com</a>
            </div>
        </div>
    );
};

export default PageError;
