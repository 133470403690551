import React, { useState } from "react";
import BgLayout from "../common/BgLayout";
import CheckIn from "./CheckIn";
import checkInImage from "../../assets/images/check-in.jpeg"
import feedbackImage from "../../assets/images/feedback.jpeg"
import Feedback from "./Feedback";

const Clover = ({ data = {} }) => {
    const [customer, setCustomer] = useState({ customer: data?.customer, feedbackQuestion: data?.feedback_question  });

    if (customer?.customer?._id) {
        return (
            <BgLayout bgImage={feedbackImage} businessName={data?.business?.name}>
                <Feedback
                    business={data?.business}
                    loyaltyProgram={data?.loyalty_program}
                    pointsEarned={data?.points_earned}
                    feedbackQuestion={customer?.feedbackQuestion}
                    customerId={customer?.customer?._id}
                    customerName={customer.customer?.first_name}
                    isCheckedIn={data?.is_checked_in}
                />
            </BgLayout>
        )
    }
    if (data?.business) {
        return (
            <BgLayout bgImage={checkInImage} businessName={data?.business?.name}>
                <CheckIn
                    business={data?.business}
                    loyaltyProgram={data?.loyalty_program}
                    pointsEarned={data?.points_earned}
                    setCustomer={setCustomer}
                />
            </BgLayout>
        );
    }
    return null;
};

export default Clover;
