import { useEffect, useState, useRef, useCallback } from "react";
import { CancelToken, isCancel } from "axios";
import apiClient from "../../api";

const useSubmit = () => {
    const [fetching, setfetching] = useState(false);
    const cancel = useRef();

    useEffect(() => {
        // cancel request on unmount
        return () => {
            if (cancel.current) {
                cancel.current();
            }
        };
    }, []);

    const submit = useCallback(async (config = {}) => {
        if (cancel?.current) {
            // cancel old request
            cancel.current();
        }
        setfetching(true);
        return apiClient({
            ...config,
            // create new cancel token
            cancelToken: new CancelToken((c) => {
                cancel.current = c;
            }),
        })
            .then((res) => {
                setfetching(false);
                return res.data
            })
            .catch((err) => {
                if (isCancel(err)) {
                    err.isCancelled = true;
                    throw err;
                } else {
                    setfetching(false);
                    const error = new Error(err?.response?.data?.message ?? "Something went wrong");
                    error.isCancelled = false;
                    throw error;
                }
            })
    }, []);

    return [fetching, submit];
};

export default useSubmit;
