import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/anims/loading.json";

const PageLoader = () => {
    return (
        <div
            style={{
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#121212",
            }}
        >
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData,
                }}
                height={100}
                width={100}
                isStopped={false}
                isPaused={false}
            />
        </div>
    );
};

export default PageLoader;
