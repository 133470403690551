import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Snackbar,
    Typography,
} from "@material-ui/core";
import Lottie from "react-lottie";
import * as feedbackAnim from "../../assets/anims/feedback.json";
import { BgLayoutContent } from "../common/BgLayout";
import { useHistory } from "react-router-dom";
import useSubmit from "../../hooks/http/useSubmit";
import { Alert } from "@material-ui/lab";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    earned: {
        textAlign: "center",
        fontWeight: 500,
    },
    title: {
        textAlign: "center",
        margin: theme.spacing(2, 0, 2),
    },
    downloadPass: {
        margin: theme.spacing(1, 0, 4),
    },
    feedbackCaption: {
        textAlign: "center",
    },
    question: {
        margin: theme.spacing(3, 0, 2, 0),
        fontWeight: 500,
    },
    answers: {
        marginTop: theme.spacing(0.1),
    },
    errorBox: {
        color: "red",
        fontSize: "14px",
        margin: theme.spacing(1, 0, 0),
        textAlign: "left",
    },
    divider: {
        border: "1px solid rgba(255,255,255,0.3)",
        margin: "35px 0px",
    },
    ansBtn: {
        width: "100%",
        borderColor: "#fff",
        textTransform: "none",
    },
}));

const timeout = 3000;

const NoFeedback = ({ customerId }) => {
    const history = useHistory();

    useEffect(() => {
        const id = setTimeout(() => {
            history.replace(`/customer/${customerId}/pass/download`);
        }, timeout);
        return () => {
            clearTimeout(id);
        };
    }, []);

    return null;
};

const SuccessToast = ({ data = {} }) => {
    return (
        <>
            <Snackbar open autoHideDuration={timeout}>
                <Alert severity="success">{data.message}</Alert>
            </Snackbar>
            <NoFeedback customerId={data?.customer?._id} />
        </>
    );
};

const FeedbackForm = ({ feedbackQuestion = {}, customerId }) => {
    const classes = useStyles();
    const history = useHistory();
    const [fetching, submit] = useSubmit();
    const [errMsg, setErr] = useState("");
    const [success, setSucess] = useState(null);

    const onFeedback = async (data) => {
        try {
            const res = await submit({
                url: `feedback/question/${feedbackQuestion?._id}/customer/${customerId}/answer`,
                method: "POST",
                data,
            });
            setSucess(res);
            history.replace(`/customer/${customerId}/pass/download`);
        } catch (err) {
            //handle error
            if (!err.isCancelled) {
                setErr(err.message);
            }
        }
    };

    return (
        <>
            {success && <SuccessToast data={success} />}
            <div className={classes.divider} />
            <Typography className={classes.feedbackCaption} variant="body2">
                Help us improve and earn <strong>{feedbackQuestion.points}</strong> more points!
            </Typography>
            <Typography
                className={classes.question}
                variant="h6"
                color="textPrimary"
            >
                {feedbackQuestion.question}
            </Typography>
            <Grid className={classes.answers} container spacing={3}>
                {feedbackQuestion.answer_type.types.map((answer) => (
                    <Grid item xs={6} key={answer}>
                        <Button
                            variant="outlined"
                            size="large"
                            className={classes.ansBtn}
                            disableElevation
                            disabled={fetching}
                            onClick={() =>
                                onFeedback({
                                    answer,
                                    status: "Answered",
                                })
                            }
                        >
                            {answer}
                        </Button>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        style={{ width: "100%", borderColor: "#fff" }}
                        disableElevation
                        onClick={() => onFeedback({ status: "Skipped" })}
                        disabled={fetching}
                    >
                        Skip
                    </Button>
                </Grid>
                {errMsg && <div className={classes.errorBox}>{errMsg}</div>}
            </Grid>
        </>
    );
};

const Feedback = ({
    customerId,
    customerName,
    pointsEarned,
    feedbackQuestion,
    isCheckedIn,
}) => {
    const classes = useStyles();

    return (
        <BgLayoutContent>
            <Helmet>
                <title>Feedback</title>
            </Helmet>
            <div className={classes.paper}>
                <Lottie
                    height={120}
                    options={{
                        loop: false,
                        autoplay: true,
                        animationData: feedbackAnim.default,
                    }}
                />
                <Typography
                    className={classes.title}
                    variant="h6"
                    color="textPrimary"
                >
                    <Box>
                        Hi {customerName}, 
                        {isCheckedIn
                            ? " You have checked in already!"
                            : " You have checked in successfully!"}
                        <br />
                        {pointsEarned} points has been added to your card.
                    </Box>
                </Typography>
                {feedbackQuestion ? (
                    <FeedbackForm
                        feedbackQuestion={feedbackQuestion}
                        customerId={customerId}
                    />
                ) : (
                    <NoFeedback customerId={customerId} />
                )}
            </div>
        </BgLayoutContent>
    );
};

export default Feedback;
