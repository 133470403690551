import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#fff",
            dark: "#000",
        },
        secondary: {
            main: "#1976d2",
            dark: "#fff",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#fff",
            dark: "#fff",
        },
        text: {
            primary: "#fff",
            dark: "#fff",
        },
        notchedOutline: {
            borderColor: "#fff",
        },
        action: {
            hoverOpacity: 0.5,
            disabledOpacity: 0.5,
            activatedOpacity: 1,
        },
    },
    typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
    },
});

export default theme;
